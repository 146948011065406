.flex-centering {
  display: flex;
  flex-direction: column;
  height: inherit;
  align-items: center;
  justify-content: center;
}

.center-content {
  justify-content: center;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-horizontal {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.MuiDataGrid-columnHeaders {
  border-bottom: 1px solid #0000001A;
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}
.MuiDataGrid-virtualScroller {
  border-top: 1px solid #0000001A;
}
.MuiDataGrid-row,
.MuiDataGrid-cell {
  border-bottom: 1px solid #0000001A !important;
}

.MuiTableCell-columnHeaders {
  border-bottom: 1px solid #0000001A;
}

.MuiTable-root{
  border: 1px solid #0000001A !important;
}
.MuiTableCell-root
{
  border-top: 1px solid #0000001A !important;
  border-bottom: 1px solid #0000001A !important
}
.MuiTableCell-head{
  font-weight: bold !important;
}
.MuiTableContainer-root{
  box-shadow: (0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 
}
.MuiTableBody-root {
  border-left: 1px solid #0000001A !important;
  border-right: 1px solid #0000001A !important;
}

