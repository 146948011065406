.chat-container {
  position: absolute;
  top: 0;
  bottom: 0rem;
  right: 0;
  left: 0;
  padding: 1rem 0rem;

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
  }

  .chat-main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .chat-header-main {
      display: flex;
      flex-direction: column;
      width: 80%;
      @media (max-width: 768px) {
        width: 100%;
      }
      .logo-header {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        justify-content: space-between;
        padding: 0rem 5rem;
        @media (max-width: 768px) {
          padding: 0;
        }
        .title {
          display: flex;
          flex-direction: column;
          font-weight: bold;
          font-size: 18px;
          font-family: Inter, sans-serif;
          color: #3f76d0;
          .sub-title {
            align-self: center;
          }
        }
        .pcare-logo {
          width: 10rem;
          object-fit: cover;

          @media (max-width: 768px) {
            padding-left: 0rem;
          }
        }
      }
      .chat-header {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-shrink: 0;
        width: 100%;
        row-gap: 0.5rem;
        align-self: center;
        padding: 0rem 5rem;
        gap: 0.6rem;

        @media (max-width: 768px) {
          flex-shrink: 0;
          width: 100%;
          padding: 0rem 0rem;
        }
      }
    }

    .nlux-AiChat-root {
      .nlux-comp-exceptionBox {
        z-index: 10;
      }

      > .nlux-chatRoom-container {
        display: flex;
        padding-bottom: 10px;
        border-radius: 0;
        z-index: 20;

        .nlux-launchPad-container {
          > .nlux-conversationStarters-container {
            flex: initial;
            display: flex;
            flex-grow: 0.5;
            flex-wrap: wrap;
            align-content: flex-start;
          }

          .nlux-comp-welcomeMessage {
            > .nlux-comp-welcomeMessage-personaName {
              text-align: center;
            }
            .nlux-comp-welcomeMessage-text {
              text-align: center;
            }
            .nlux-comp-avatar {
              display: none;
            }
          }
        }

        .nlux-conversationStarters-container {
          overflow: auto;

          > .nlux-comp-conversationStarters {
            align-items: flex-start;
            flex-wrap: wrap;

            > .nlux-comp-conversationStarter {
              background-color: white;
              border: 1px solid lightgray;
              border-radius: 5px;

              &:hover {
                background-color: #f4f4f5;
                /* Change background color on hover */
              }

              .nlux-comp-conversationStarter-prompt {
                height: 80%;
              }
            }
          }
        }

        > .nlux-composer-container {
          width: 100%;
          border: 1px solid gray;
          align-self: center;
          border-radius: 5px;

          @media (max-width: 768px) {
            width: 100%;
          }

          .nlux-comp-composer {
            > textarea {
              overflow: auto;
              cursor: default;
              font-size: 14.2px;
              padding: 0.5rem;
            }
          }
        }
      }

      &.nlux-theme-nova[data-color-scheme='light'] {
        padding: 0rem 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;

        @media (max-width: 768px) {
          padding: 0rem 0rem;
          width: 100%;
        }
      }

      .nlux-comp-chatItem {
        gap: 0;
        margin-bottom: 0rem;
        .nlux-comp-chatItem-participantInfo {
          display: none;
        }
        &.nlux-comp-chatItem--bubblesLayout {
          &.nlux-comp-chatItem--sent {
            > .nlux-comp-message {
              background-color: #3f76d0;
              color: white;
              border-radius: 5px;
            }
          }

          &.nlux-comp-chatItem--received {
            align-items: flex-start;
            gap: 0.5rem;

            > .nlux-comp-message {
              width: calc(100% - 2rem);
              background-color: white;
            }
          }
        }
      }

      > .nlux-chatRoom-container:not(.nlux-chatRoom-starting)
        > .nlux-conversation-container {
        width: 100%;
        align-self: center;
        background-color: #f4f5f6;
        padding: 0rem 0.8rem;
        padding-bottom: 1rem;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      .nlux-chatSegment {
        display: flex;
        flex-direction: column;

        .nlux-comp-chatItem--sent {
          width: max-content;
          align-self: flex-end;
          max-width: 80%;
          padding-left: 0;
          .nlux-comp-message {
            margin: 1rem 0rem;
          }

          @media (max-width: 768px) {
            max-width: 100%;
            padding-left: 5rem;
          }
        }

        .nlux-comp-chatItem--received {
          width: max-content;
          max-width: 80%;
          padding-right: 0;

          @media (max-width: 768px) {
            max-width: 100%;
            padding-right: 0;
          }
        }
      }
    }
  }
}
