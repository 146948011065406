body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Roboto-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: inherit;
}

code {
  font-family: 'Roboto-Regular';
}

div,
h1,
h2,
h3,
a,
p,
h4,
h5,
h6,
ul,
li {
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  src: local('Roboto-Regular'),
    url(../assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  font-weight: 400;
  src: local('Roboto-Bold'),
    url(../assets/fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  font-weight: 400;
  src: local('Roboto-BoldItalic'),
    url(../assets/fonts/Roboto-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Italic';
  font-weight: 400;
  src: local('Roboto-Italic'),
    url(../assets/fonts/Roboto-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  font-weight: 400;
  src: local('Roboto-Medium'),
    url(../assets/fonts/Roboto-Medium.ttf) format('truetype');
}

