.custom-response-renderer {
  position: relative;
  width: 100%;
  background-color: #f4f4f591;
  border-radius: 5px;

  .response-container {
    position: inherit;
    z-index: 999;
    padding: 10px;
  }
  .info-container {
    position: relative;
    z-index: 999;
    padding: 5px 10px;
    color: #a0a0a0;
    font-size: 0.8em;
    text-align: right;
  }
}

.custom-prompt-renderer {
  position: relative;
  width: 100%;
  background-color: #3f76d0;
  color: white;
  border-radius: 5px;
  .prompt-container {
    position: relative;
    .clipboard-icon {
      display: none;
      cursor: pointer;
      position: absolute;
      right: -10px;
      color: white;
      background-color: #3f76d0;
      padding: 2px;
      padding-left: 0.4rem;
    }
  }
  .prompt-container:hover .clipboard-icon {
    display: inline;
  }
}