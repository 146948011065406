/* select-dropdown.scss */

.select-container {
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    flex: auto;

  }

  .select-control {
    flex: 1;

    @media (max-width: 768px) {
      width: 100%;
    }

    .select-input {
      height: 50px;
      border-radius: 5px;
    }
  }
}
